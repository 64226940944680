import React from "react"
import "./mystyles.scss"

import SEO from "../components/seo"

const IndexPage = () => {
  return (
    <section className="hero is-fullheight">
      <SEO />
      <div className="hero-body">
        <div className="container">
          <h1 className="title">Brecht Waerenburgh</h1>
          <h2 className="subtitle mb-0">
            Dierenarts - Rundvee en kleine herkauwers - <a href="tel:+32494122679">0494 12 26 79</a>
          </h2>
          <h2 className="subtitle mb-0">
            <a href="mailto:info@brechtwaerenburgh.be">
              info@brechtwaerenburgh.be
            </a>
          </h2>
        </div>
      </div>
    </section>
  )
}

export default IndexPage
